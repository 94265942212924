/* eslint-disable no-async-promise-executor */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/prefer-object-literal */
/* eslint-disable sonarjs/no-duplicate-string */
import { Base64Assets } from '../../assets';
import { get } from '../../services/apiservice';
import { jsonToCsv } from '../../utils/common/utils';
import { saveAs } from 'file-saver';
import config from './AtpTestReports.config.json';
import ENDPOINTS from '../../services/endpoints';
import ExcelJs from 'exceljs';
import moment from 'moment';
import { getValue } from '../../utils/report/utils';
import JsPdf from 'jspdf';
// eslint-disable-next-line no-unused-vars
import { autoTable } from 'jspdf-autotable';
import html2canvas from 'html2canvas';
/**
 * Formats the ATP Sites response
 * @param {{String}} param0
 * @returns {{value: String, label: String, children?: {}}}
 */
const formatATPSitesResponse = ({ sites }) => {
  const getChildren = data => {
    if (typeof data === 'object') {
      if (Array.isArray(data)) {
        return data.map(item => ({
          value: item,
          label: item,
        }));
      } else {
        return Object.keys(data).map(subKey => ({
          value: subKey,
          label: subKey,
          children: getChildren(data[subKey]),
        }));
      }
    }
  };
  return getChildren(sites);
};

/**
 * Formats ATP SMS Response
 * @param {Array} data
 * @param {Boolean} isReport
 * @returns {Array}
 */
const formatSMSResponse = (data = [], isReport = false) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return isReport ? {} : [];
  const smsData = data
    .filter(item => item.test_name === TYPES.sms)
    .map(el => {
      const reportPayload = JSON.parse(el?.report_payload);
      return {
        ...el,
        report_payload: reportPayload,
        atp_test_error: reportPayload?.error_text ?? reportPayload?.message_send_error,
        atp_test_status: el?.test_status ?? reportPayload?.atp_test_status,
      };
    });
  const consolidatedData = {};
  smsData.forEach(item => {
    consolidatedData[item?.test_id] = smsData.filter(el => el?.test_id === item?.test_id);
  });
  if (!Object.keys(consolidatedData).length) return isReport ? {} : [];
  if (isReport) {
    const res = {};
    Object.keys(consolidatedData).forEach(key => {
      const senderPayload =
        consolidatedData?.[key]?.find(
          el =>
            el.payload_uploaded_by === 'sms-sender' &&
            el.reporting_type === 'sms_sent_confirmation',
        ) ?? {};
      res[key] = senderPayload;
    });
    return res;
  }
  if (!Object.keys(consolidatedData).length) return [];
  return Object.keys(consolidatedData).map(key => {
    const senderPayload =
      consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'sms-sender') ?? {};
    const sentSuccessfully =
      consolidatedData?.[key]?.find(
        el =>
          el.payload_uploaded_by === 'sms-sender' &&
          el.reporting_type === 'sms_sent_confirmation' &&
          el.atp_test_status === 'Success',
      ) ?? null;
    const receivedAck =
      consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'sms-recipient') ?? null;
    const res = {
      ...senderPayload,
      detailsTable: formatResponseForDetailedView(consolidatedData[key], TYPES.sms),
      details: consolidatedData[key],
      // atp_test_status: 'Failed',
    };
    if (
      sentSuccessfully &&
      receivedAck &&
      moment(receivedAck?.reporting_datetime).diff(
        moment(sentSuccessfully?.reporting_datetime),
        'milliseconds',
      ) > DEFAULT_TIMEOUT_DURATION
    ) {
      res.atp_test_status = 'Failed';
    }
    return res;
  });
};

/**
 * Formats ATP VoNR Mobility Response
 * @param {Array} data
 * @param {Boolean} isReport
 * @returns {Array}
 */
const formatVoNRMobilityResponse = (data = [], isReport = false) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return isReport ? {} : [];
  const vonrData = data
    .filter(item => item.test_name === TYPES.vonrMobility)
    .map(el => ({
      ...el,
      report_payload: JSON?.parse(el?.report_payload),
    }));
  const consolidatedData = {};
  vonrData.forEach(item => {
    consolidatedData[item?.test_id] = vonrData.filter(el => el?.test_id === item?.test_id);
  });
  if (!Object.keys(consolidatedData).length) return isReport ? {} : [];
  if (isReport) {
    const res = {};
    Object.keys(consolidatedData).forEach(key => {
      const senderPayload =
        consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'caller') ?? {};
      res[key] = {
        ...senderPayload,
        atp_test_status: senderPayload?.test_status,
        atp_test_error: senderPayload?.report_payload?.error_text ?? '',
      };
    });
    return res;
  }
  if (!Object.keys(consolidatedData).length) return [];
  return Object.keys(consolidatedData).map(key => {
    const senderPayload =
      consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'caller') ?? {};
    return {
      ...senderPayload,
      detailsTable: formatResponseForDetailedView(consolidatedData[key], TYPES.vonrMobility),
      details: consolidatedData[key],
      atp_test_status: senderPayload?.test_status,
      atp_test_error: senderPayload?.report_payload?.error_text ?? '',
    };
  });
};

/**
 * Formats ATP VoNR Response
 * @param {Array} data
 * @param {Boolean} isReport
 * @returns {Array}
 */
const formatVoNRResponse = (data = [], isReport = false) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return isReport ? {} : [];
  const vonrData = data
    .filter(item => item.test_name === TYPES.vonr)
    .map(el => ({
      ...el,
      report_payload: JSON?.parse(el?.report_payload),
    }));
  const consolidatedData = {};
  vonrData.forEach(item => {
    consolidatedData[item?.test_id] = vonrData.filter(el => el?.test_id === item?.test_id);
  });
  if (!Object.keys(consolidatedData).length) return isReport ? {} : [];
  if (isReport) {
    const res = {};
    Object.keys(consolidatedData).forEach(key => {
      const senderPayload =
        consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'caller') ?? {};
      res[key] = {
        ...senderPayload,
        atp_test_status: senderPayload?.test_status,
        atp_test_error: senderPayload?.report_payload?.error_text ?? '',
      };
    });
    return res;
  }
  if (!Object.keys(consolidatedData).length) return [];
  return Object.keys(consolidatedData).map(key => {
    const senderPayload =
      consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'caller') ?? {};
    return {
      ...senderPayload,
      detailsTable: formatResponseForDetailedView(consolidatedData[key], TYPES.vonr),
      details: consolidatedData[key],
      atp_test_status: senderPayload?.test_status,
      atp_test_error: senderPayload?.report_payload?.error_text ?? '',
    };
  });
};

/**
 * Formats ATP ViNR Response
 * @param {Array} data
 * @param {Boolean} isReport
 * @returns {Array}
 */
const formatViNRResponse = (data = [], isReport = false) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return isReport ? {} : [];
  const vonrData = data
    .filter(item => item.test_name === TYPES.vinr)
    .map(el => ({
      ...el,
      report_payload: JSON?.parse(el?.report_payload),
    }));
  const consolidatedData = {};
  vonrData.forEach(item => {
    consolidatedData[item?.test_id] = vonrData.filter(el => el?.test_id === item?.test_id);
  });
  if (!Object.keys(consolidatedData).length) return isReport ? {} : [];
  if (isReport) {
    const res = {};
    Object.keys(consolidatedData).forEach(key => {
      const senderPayload =
        consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'caller') ?? {};
      senderPayload.atp_test_status = senderPayload?.test_status;
      senderPayload.atp_test_error = senderPayload?.report_payload?.error_text ?? '';
      res[key] = senderPayload;
    });
    return res;
  }
  return Object.keys(consolidatedData).map(key => {
    const senderPayload =
      consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'caller') ?? {};
    return {
      ...senderPayload,
      detailsTable: formatResponseForDetailedView(consolidatedData[key], TYPES.vinr),
      details: consolidatedData[key],
      atp_test_status: senderPayload?.test_status,
      atp_test_error: senderPayload?.report_payload?.error_text ?? '',
    };
  });
};

/**
 * Formats ATP Ping Response
 * @param {Array} data
 * @param {Boolean} isReport
 * @returns {Array}
 */
const formatPingResponse = (data = [], isReport = false) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return isReport ? {} : [];
  const pingData = data
    .filter(item => item.test_name === TYPES.ping)
    .map(el => ({
      ...el,
      report_payload: JSON.parse(el?.report_payload),
    }));
  if (!pingData.length) return isReport ? {} : [];
  if (isReport) {
    const res = {};
    pingData.forEach(item => {
      res[item?.test_id] = item;
    });
    return res;
  }
  return pingData.map(item => {
    const reportPayload = item?.report_payload;
    const details = {
      ...item,
    };
    const res = {
      ...item,
      details,
      detailsTable: formatResponseForDetailedView([details], TYPES.ping),
      atp_test_status: item?.test_status ?? reportPayload?.atp_test_status,
      atp_test_error: reportPayload?.error_text,
      graph: {},
    };
    if (reportPayload?.latency_data) {
      res.graph.data = Object.keys(reportPayload?.latency_data).length
        ? Object.keys(reportPayload?.latency_data).map(key => ({
            time: parseFloat(key),
            latency: parseFloat(reportPayload?.latency_data?.[key]),
            name: reportPayload?.latency_data?.[key] + 'ms',
          }))
        : [];
      res.graph.units = {
        x_axis: 's',
        y_axis: 'ms',
      };
    }
    return res;
  });
};

const getIperfReportType = type => {
  const res = {
    testName: 'atp-iperf-test',
  };
  switch (type) {
    case 'tu':
    case 'atp-iperf-test-tcp-upload':
      return {
        ...res,
        reportingType: 'tcp_upload_throughput',
        mappingId: 'iperfTU',
      };
    case 'td':
    case 'atp-iperf-test-tcp-download':
      return {
        ...res,
        reportingType: 'tcp_download_throughput',
        mappingId: 'iperfTD',
      };
    case 'uu':
    case 'atp-iperf-test-udp-upload':
      return {
        ...res,
        reportingType: 'udp_upload_throughput',
        mappingId: 'iperfUU',
      };
    case 'ud':
    case 'atp-iperf-test-udp-download':
      return {
        ...res,
        reportingType: 'udp_download_throughput',
        mappingId: 'iperfUD',
      };
    default:
      return null;
  }
};

/**
 * Formats ATP iPerf Response
 * @param {Array} data
 * @param {String} type
 * @param {Boolean} isReport
 * @returns {Array}
 */
const formatIperfResponse = (data = [], type, isReport = false) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return isReport ? {} : [];
  const { testName, reportingType, mappingId } = getIperfReportType(type);
  const iperfData = data
    .filter(item => item.test_name === testName && item.reporting_type === reportingType)
    .map(el => {
      const reportPayload = JSON.parse(el?.report_payload);
      return {
        ...el,
        report_payload: {
          ...reportPayload,
          atp_test_status: reportPayload?.atp_test_status ?? 'na',
          atp_test_error: reportPayload?.error_text ?? '',
          min_bandwidth:
            !reportPayload?.min_bandwidth || !reportPayload?.bandwidth_unit
              ? '--'
              : reportPayload?.min_bandwidth + ' ' + reportPayload?.bandwidth_unit,
          max_bandwidth:
            !reportPayload?.max_bandwidth || !reportPayload?.bandwidth_unit
              ? '--'
              : reportPayload?.max_bandwidth + ' ' + reportPayload?.bandwidth_unit,
          avg_bandwidth:
            !reportPayload?.avg_bandwidth || !reportPayload?.bandwidth_unit
              ? '--'
              : reportPayload?.avg_bandwidth + ' ' + reportPayload?.bandwidth_unit,
        },
      };
    });
  if (!iperfData.length) return isReport ? {} : [];
  if (isReport) {
    const res = {};
    iperfData.forEach(item => {
      res[item.test_id] = item;
    });
    return res;
  }
  return iperfData?.map(item => {
    const reportPayload = item?.report_payload;
    const res = {
      ...item,
      detailsTable: formatResponseForDetailedView([item], TYPES[mappingId]),
      graph: {},
    };
    if (reportPayload?.bandwidth_data) {
      res.graph.data = Object.keys(reportPayload?.bandwidth_data).length
        ? Object.keys(reportPayload?.bandwidth_data).map(key => ({
            time: parseFloat(key.split('-')?.[1]),
            bandwidth: parseFloat(reportPayload?.bandwidth_data?.[key]),
            name: reportPayload?.bandwidth_data?.[key] + reportPayload?.bandwidth_unit,
          }))
        : [];
      res.graph.units = {
        x_axis: reportPayload?.bandwidth_unit?.split('/')?.[1],
        y_axis: reportPayload?.bandwidth_unit?.split('/')?.[0],
      };
    }
    return res;
  });
};

/**
 * Formats ATP MMS Response
 * @param {Array} data
 * @param {Boolean} isReport
 * @returns {Array}
 */
const formatMMSResponse = (data = [], isReport = false) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return isReport ? {} : [];
  const mmsData = data
    .filter(item => item.test_name === TYPES.mms)
    .map(el => {
      const reportPayload = JSON.parse(el?.report_payload);
      return {
        ...el,
        atp_test_error: reportPayload?.error_text ?? reportPayload?.message_send_error,
        atp_test_status: el?.test_status ?? reportPayload?.atp_test_status,
      };
    });
  const consolidatedData = {};
  mmsData.forEach(item => {
    consolidatedData[item?.test_id] = mmsData.filter(el => el?.test_id === item?.test_id);
  });
  if (!Object.keys(consolidatedData).length) return isReport ? {} : [];
  if (isReport) {
    const res = {};
    if (!Object.keys(consolidatedData).length) return res;
    Object.keys(consolidatedData).forEach(key => {
      const senderPayload =
        consolidatedData?.[key]?.find(
          el =>
            el.payload_uploaded_by === 'mms-sender' &&
            el.reporting_type === 'mms_sent_confirmation',
        ) ?? {};
      res[key] = senderPayload;
    });
    return res;
  }
  return Object.keys(consolidatedData).map(key => {
    const senderPayload =
      consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'mms-sender') ?? {};
    const sentSuccessfully =
      consolidatedData?.[key]?.find(
        el =>
          el.payload_uploaded_by === 'mms-sender' &&
          el.reporting_type === 'mms_sent_confirmation' &&
          el.atp_test_status === 'Success',
      ) ?? null;
    const receivedAck =
      consolidatedData?.[key]?.find(el => el.payload_uploaded_by === 'mms-recipient') ?? null;
    const res = {
      ...senderPayload,
      detailsTable: formatResponseForDetailedView(consolidatedData[key], TYPES.mms),
      details: consolidatedData[key],
      // atp_test_status: 'Failed',
    };
    if (
      sentSuccessfully &&
      receivedAck &&
      moment(receivedAck?.reporting_datetime).diff(
        moment(sentSuccessfully?.reporting_datetime),
        'milliseconds',
      ) > DEFAULT_TIMEOUT_DURATION
    ) {
      res.atp_test_status = 'Failed';
    }
    return res;
  });
};

/**
 * Formatter function for formatting the data for detail modal view
 * @param {Array} data
 * @param {String} type
 * @returns {Array}
 */
const formatResponseForDetailedView = (data = [], type = TYPES.sms) =>
  Object.keys(config[type]?.modal?.headers).map(key => ({
    rowType: config[type]?.modal?.headers?.[key],
    src: getValue(
      data?.find(
        item => item?.payload_uploaded_by === config?.[type]?.modal?.payload_uploaded_by?.src,
      ),
      key,
    ),
    rec: getValue(
      data?.find(
        item => item?.payload_uploaded_by === config?.[type]?.modal?.payload_uploaded_by?.rec,
      ),
      key,
    ),
  }));

/**
 * Formatter function for formatting the site details
 * @param {Object{}} data
 * @param {Boolean} isCsv
 * @returns {Object{} | Array}
 */
const formatSiteDetailDataForReport = (data = {}, isCsv = false) => {
  const config = {
    marketID: 'Market ID',
    market: 'Market Name',
    siteID: 'Site ID',
    name: 'Site Name',
  };
  if (!Object.keys(data).length) return isCsv ? {} : [];
  // Generates the response as a object with key value pair
  if (isCsv) {
    const res = {};
    Object.keys(config).forEach(key => {
      if (key === 'marketID') res[config[key]] = data?.name?.slice(0, 5);
      else res[config[key]] = data?.[key] ?? '';
    });
    return res;
  }
  // Generates the response as array of arrays with two values holding the key and the value
  return Object.keys(config).map(key =>
    key.includes('+')
      ? [config[key], key.split('+').reduce((acc, curr) => data?.[acc] ?? '' + data?.[curr] ?? '')]
      : [config[key], data?.[key] ?? ''],
  );
};

const constructObj = (data = {}, config = {}, defaultValue = undefined) => {
  const res = {};
  Object.keys(config).forEach(key => {
    if (key.includes('+')) {
      let val = '';
      key.split('+').forEach(subKey => {
        val += getValue(data, subKey, defaultValue);
      });
      res[config[key]] = val;
    } else {
      res[config[key]] = getValue(data, key, defaultValue);
      if (data?.test_name === 'na' && data?.entry_id === 'na') {
        if (key === 'report_payload.testing_nr_cell_data.nr_cell_sector') {
          res[config[key]] = data?.testing_sector;
        } else if (key === 'report_payload.testing_nr_cell_data.nr_cell_band') {
          res[config[key]] = data?.testing_band;
        }
      }
    }
  });
  return res;
};

const destructureReportPayload = (data = {}) => {
  if (!data?.report_payload || typeof data?.report_payload !== 'string') return data;
  return {
    ...data,
    report_payload: JSON.parse(data?.report_payload),
  };
};

/**
 * Formats the ATP Test response to CSV data format
 * @param {Array} data (Input array of tests)
 * @param {Object{}} siteData (Site Information)
 * @returns {Array}
 */
const formatCsvData = (data = [], siteData = {}, valueMap) => {
  return data?.reduce((result, item) => {
    let testName = item?.test_name;
    if (testName === 'atp-iperf-test') {
      let temp = item?.reporting_type?.replace('_throughput', '');
      temp = temp.replace('_', '-');
      testName += `-${temp}`;
    }
    // console.log('******', testName);
    if (config?.[`${testName}`]?.xlsx?.names) {
      result.push({
        ...constructObj(destructureReportPayload(item), config?.[`${testName}`]?.xlsx?.names),
        [config?.[`${testName}`]?.xlsx?.names?.payload_uploaded_by]:
          valueMap?.payload_uploaded_by?.[item?.payload_uploaded_by] ?? item?.payload_uploaded_by,
        ...formatSiteDetailDataForReport(siteData, true),
      });
    }
    // console.log('******\n', result.slice(-1));
    return result;
  }, []);
};

const formatPdfData = (data = [], siteData = {}, valueMap = {}) => {
  const siteDataConfig = {
    siteID: 'Site ID',
    name: 'Site Name',
    latitude: 'Site Latitude',
    longitude: 'Site Longitude',
    status: 'Status',
    market: 'Market',
    aoi: 'AOI',
    city: 'City',
    address: 'Address',
    zip: 'Zip Code',
    region: 'Region',
    // country: 'Country',
    // cell_site_group: 'Cell Site Group',
    // csr_site_type: 'CSR Site Type',
    // data_center: 'Data Center',
    num_cells: 'Number of Cells',
  };
  const mobilityTestConfig = {
    'report_payload.testing_nr_cell_data.nr_cell_sector': 'Sector',
    'report_payload.testing_nr_cell_data.nr_cell_band': 'Band',
    'report_payload.testing_nr_cell_data.nr_cell_pci': 'PCI',
    testing_nrcellid: 'Testing Cell ID',
    test_status: 'Status',
  };
  const tableData = [];
  const formattedMobilityTest = {};
  data
    .filter(
      item => item.test_name === 'atp-vonr-mobility-test' && item.payload_uploaded_by === 'caller',
    )
    .map(item => ({ ...item, report_payload: JSON.parse(item.report_payload) }))
    .forEach(item => {
      const res = {};
      Object.keys(mobilityTestConfig).forEach(key => {
        res[mobilityTestConfig[key]] = getValue(item, key, '-');
      });
      formattedMobilityTest[item.test_id] = res;
    });
  const mobilityTestData = {
    headers: Object.values(mobilityTestConfig),
    data: formattedMobilityTest,
  };
  Object.keys(config).forEach(configName => {
    if (configName.includes('iperf')) {
      const { testName, reportingType } = getIperfReportType(configName);
      const unTestedData = data.filter(item => item.test_name === 'na');
      tableData.push({
        key: testName,
        title: config?.[configName]?.pdf?.title,
        headers: Object.values(config?.[configName]?.pdf?.names),
        cellWidth: config?.[configName]?.pdf?.tableCellWidth ?? 30,
        data: [
          ...data?.filter(
            item => item?.test_name === testName && item?.reporting_type === reportingType,
          ),
          ...unTestedData,
        ]
          ?.map(item => {
            Object?.keys(valueMap)?.forEach(
              mapKey => (item[mapKey] = valueMap?.[mapKey]?.[item?.[mapKey]]),
            );
            return item;
          })
          ?.map(item =>
            constructObj(destructureReportPayload(item), config?.[configName]?.pdf?.names, '-'),
          ),
      });
    } else {
      tableData.push({
        key: configName,
        title: config?.[configName]?.pdf?.title,
        headers: Object.values(config?.[configName]?.pdf?.names ?? {}),
        data: data
          ?.filter(item => item?.test_name === configName || item?.test_name === 'na')
          ?.map(item => {
            Object?.keys(valueMap)?.forEach(
              mapKey => (item[mapKey] = valueMap?.[mapKey]?.[item?.[mapKey]]),
            );
            return item;
          })
          ?.map(item =>
            constructObj(destructureReportPayload(item), config?.[configName]?.pdf?.names, '-'),
          ),
      });
    }
  });
  console.log(tableData);
  return {
    tableData,
    siteData: constructObj(siteData, siteDataConfig),
    mobilityTestData: mobilityTestData,
  };
};

/**
 * Formats the ATP Test response to XLSX data format
 * @param {Array} data
 * @returns {Array}
 */
const formatXlsxData = (data = []) => {
  if (!data || !Array.isArray(data) || data.length === 0) return [];

  const formatReportData = (items = {}, config = {}) => {
    const res = {
      data: [],
      properties: {
        title: [],
        colWidth: [],
      },
    };
    if (!Object.keys(items).length) return res;
    res.data = [
      [config?.title],
      ...Object.keys(config?.names).map(name => [
        config?.names[name],
        ...Object.keys(items).map(key => getValue(items[key], name)),
      ]),
    ];
    return res;
  };
  const sms = formatReportData(formatSMSResponse(data, true), config?.[TYPES.sms]?.xlsx);
  const vonr = formatReportData(formatVoNRResponse(data, true), config?.[TYPES.vonr]?.xlsx);
  const vinr = formatReportData(formatViNRResponse(data, true), config?.[TYPES.vinr]?.xlsx);
  const ping = formatReportData(formatPingResponse(data, true), config?.[TYPES.ping]?.xlsx);
  const mms = formatReportData(formatMMSResponse(data, true), config?.[TYPES.mms]?.xlsx);
  const iperfTd = formatReportData(
    formatIperfResponse(data, 'td', true),
    config?.[TYPES.iperfTD]?.xlsx,
  );
  const iperfTu = formatReportData(
    formatIperfResponse(data, 'tu', true),
    config?.[TYPES.iperfTU]?.xlsx,
  );
  const iperfUd = formatReportData(
    formatIperfResponse(data, 'ud', true),
    config?.[TYPES.iperfUD]?.xlsx,
  );
  const iperfUu = formatReportData(
    formatIperfResponse(data, 'uu', true),
    config?.[TYPES.iperfUU]?.xlsx,
  );
  const concData = [];
  if (sms?.data.length) {
    concData.push(...sms?.data);
    concData.push([]);
  }
  if (vonr?.data.length) {
    concData.push(...vonr?.data);
    concData.push([]);
  }
  if (vinr?.data.length) {
    concData.push(...vinr?.data);
    concData.push([]);
  }
  if (ping?.data.length) {
    concData.push(...ping?.data);
    concData.push([]);
  }
  if (mms?.data.length) {
    concData.push(...mms?.data);
    concData.push([]);
  }
  if (iperfTd?.data.length) {
    concData.push(...iperfTd?.data);
    concData.push([]);
  }
  if (iperfTu?.data.length) {
    concData.push(...iperfTu?.data);
    concData.push([]);
  }
  if (iperfUd?.data.length) {
    concData.push(...iperfUd?.data);
    concData.push([]);
  }
  if (iperfUu?.data.length) {
    concData.push(...iperfUu?.data);
    concData.push([]);
  }
  const titleBreaks = [];
  if (concData.length > 0) {
    titleBreaks.push(-1); // To Merge the first row
  }
  for (let i = 0; i < concData.length; i++) {
    if (Array.isArray(concData[i]) && concData[i].length === 0 && i !== concData.length - 1) {
      titleBreaks.push(i);
    }
  }
  return {
    data: concData,
    properties: {
      title: titleBreaks.map(id => ({ merge: `A${id + 2}:I${id + 2}`, rowNumber: id + 2 })),
      colWidth: Array(8)
        .fill(0)
        .map(i => ({ width: 25, font: { bold: true, color: { rgb: 'FF0000' } } })),
    },
  };
};

/**
 * Formats the site details for the map view
 * @param {{}} data
 * @returns {{}}
 */
export const formatSiteDetailsForMap = (data = {}) => {
  if (!Object.keys(data).length) return {};
  return {
    name: data?.name,
    lat: parseFloat(data?.latitude),
    lng: parseFloat(data?.longitude),
    antennas: data?.antennas?.map(({ name, azimuth, latitude, longitude }) => ({
      name,
      azimuth,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    })),
  };
};

/**
 * Types of ATP tests
 */
export const TYPES = {
  iperfTD: 'atp-iperf-test-tcp-download',
  iperfTU: 'atp-iperf-test-tcp-upload',
  iperfUD: 'atp-iperf-test-udp-download',
  iperfUU: 'atp-iperf-test-udp-upload',
  mms: 'atp-mms-test',
  ping: 'atp-ping-test',
  sms: 'atp-sms-test',
  vonr: 'atp-vonr-test',
  vonrMobility: 'atp-vonr-mobility-test',
  vinr: 'atp-vinr-test',
};

/**
 * Default Date Format (changes made to this variable will affect the API calls)
 */
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Timeout duration (in milliseconds) for determining if the status of the SMS/MMS report
 */
export const DEFAULT_TIMEOUT_DURATION = 60000;

/**
 * Reports with the overview information
 */
export const WITH_OVERVIEW = [TYPES.sms, TYPES.vonr, TYPES.mms];

/**
 * Reports with Graph view
 */
export const WITH_GRAPH = [TYPES.ping, TYPES.iperfTD, TYPES.iperfTU, TYPES.iperfUD, TYPES.iperfUU];

/**
 * Async API call to fetch the ATP Sites information with a cache logic. If the data is older than 12 hours, the data is re-fetched, else cache data is served.
 * @returns {Promise}
 */
export const getCachedATPSites = () =>
  new Promise((resolve, reject) => {
    const cachedData = JSON?.parse(sessionStorage?.getItem('atp-sites'));
    if (cachedData && moment().diff(moment(cachedData?.date), 'hours') < 12) {
      resolve(cachedData?.sites);
    } else {
      get(`${ENDPOINTS.DOMAIN}${ENDPOINTS.ATP_REPORTS.GET_SITES}`)
        .then(data => {
          const response = {
            date: moment().format(),
            sites: formatATPSitesResponse(data),
          };
          sessionStorage.setItem('atp-sites', JSON.stringify(response));
          resolve(response?.sites);
        })
        .catch(error => reject(error));
    }
  });

/**
 * Returns an object with all keys set with default value provided as argument
 * @param {any} value
 * @returns
 */
export const setCommonValues = value => {
  const res = {};
  Object.values(TYPES).forEach(type => (res[type] = value));
  return res;
};

/**
 * A DOM manipulation function that adds title to each column of the Cascader
 * @returns {null}
 */
export const addCascaderTitle = () => {
  const container = document.querySelector('.rs-picker-cascader-menu-items');
  if (!document.querySelector('.rs-picker-cascader-menu-title')) {
    const titleParent = document.createElement('div');
    titleParent.className = 'rs-picker-cascader-menu-title';
    titleParent.style = 'display: flex; flex: 1; align-items: center;';
    const spanStyle = 'display: flex; flex: 1;justify-content: center; font-weight: 600;';
    const marketTitle = document.createElement('span');
    marketTitle.style = spanStyle;
    marketTitle.innerText = 'Market';
    const aoiTitle = document.createElement('span');
    aoiTitle.style = spanStyle;
    aoiTitle.innerText = 'AOI';
    const siteTitle = document.createElement('span');
    siteTitle.style = spanStyle;
    siteTitle.innerText = 'Site';
    titleParent.appendChild(marketTitle);
    titleParent.appendChild(aoiTitle);
    titleParent.appendChild(siteTitle);
    container.prepend(titleParent);
  }
  const titleContainer = document.querySelector('.rs-picker-cascader-menu-title');
  const dataContainer = titleContainer.nextElementSibling;
  if (dataContainer) {
    dataContainer.style = 'height: 100% !important;';
  }
};

/**
 * A DOM manipulation function that add the background to the HTML list having the param 'siteName'
 * @param {String} siteName
 * @returns {null}
 */
export const addActiveSitesBackground = siteName => {
  const domItemsNodeList = document.querySelectorAll('.sites-list');
  const domItemsArray = Array.from(domItemsNodeList).filter(item => item.innerHTML === siteName);
  if (domItemsArray.length) {
    domItemsArray.forEach(domItem => {
      domItem.parentNode.parentNode.classList.add('rs-cascader-active-site');
    });
  }
};

/**
 * A DOM manipulation function that removes the background from the HTML list if any
 * @returns {null}
 */
export const removeActiveSitesBackground = () =>
  new Promise((resolve, reject) => {
    try {
      const domNodeList = document.querySelectorAll('.rs-cascader-active-site');
      const domArray = Array.from(domNodeList);
      domArray.forEach(item => item.classList.remove('rs-cascader-active-site'));
      resolve();
    } catch (error) {
      reject(error);
    }
  });

/**
 * A DOM manipulation function that adds / removes the loading effect to the Cascader
 * @param {Boolean} loadingState
 */
export const setSitesColumnLoading = (loadingState = true) => {
  const cascaderColumns = document.querySelectorAll('.rs-picker-cascader-menu-column');
  const cascaderColumnsArray = Array.from(cascaderColumns);
  const sitesColumn = cascaderColumnsArray[2];
  const loader = document.createElement('i');
  loader.className = 'fa-solid fa-circle-notch fa-spin';
  if (cascaderColumnsArray.length === 3 && sitesColumn) {
    if (loadingState) {
      sitesColumn.classList.add('rs-cascader-sites-loading');
      sitesColumn.appendChild(loader);
    } else {
      sitesColumn.classList.remove('rs-cascader-sites-loading');
    }
  }
};

/**
 *
 * @param {Array} data ATP Test response(Array of objects)
 * @param {String} sitename Sitename to get the site details for the report
 * @param {String} filetype Downloadable file format (csv / xlsx)
 * @param {String} filename Download file name (default - "report")
 */
export const downloadReport = async ({
  data = [],
  sitename,
  valueMap = {},
  filetype = 'csv',
  filename = 'report',
}) => {
  try {
    const siteDataResponse = await get(
      `${ENDPOINTS.DOMAIN + ENDPOINTS.ATP_REPORTS.GET_SITE_DETAILS}?site=${sitename}`,
    );
    const workBook = new ExcelJs.Workbook();
    switch (filetype) {
      case 'csv': {
        const columns = [
          'Market ID',
          'Market Name',
          'Site ID',
          'Site Name',
          'Test Name',
          'Test ID',
          'NR Cell ID',
          'Sector',
          'Band',
          'PCI',
          'Date & Time',
          'Reported By',
          'Reporting Type',
          'Source MSISDN',
          'Recipient MSISDN',
          'RSRP',
          'RSRQ',
          'SINR',
          'TAC',
          'Signal Strength',
          'Test Server',
          'Test Server Port',
          'BW - Avg (Mbps)',
          'BW - Min (Mbps)',
          'BW - Max (Mbps)',
          'BW Threshold',
          'Latency - Min',
          'Latency - Max',
          'Latency - Avg',
          'Latency - STD DEV',
          'Error',
          'Test Status',
        ];
        jsonToCsv(
          { headers: columns, items: formatCsvData(data, siteDataResponse, valueMap) },
          filename,
        );
        break;
      }
      case 'xlsx': {
        const siteData = formatSiteDetailDataForReport(siteDataResponse);
        const { data: arr, properties } = formatXlsxData(data);
        const frontPageWorkSheet = workBook.addWorksheet('Front Page');
        // frontPageWorkSheet.properties.defaultColWidth = 30;
        const logoImageId = workBook.addImage({
          base64: Base64Assets.dishLogo,
          extension: 'jpg',
        });
        frontPageWorkSheet.properties.outlineLevelCol = 2;
        frontPageWorkSheet.addImage(logoImageId, 'B2: I22');
        frontPageWorkSheet.addRows(Array(23).fill([]));
        frontPageWorkSheet.mergeCells('B24:I26');
        frontPageWorkSheet.getCell('B24').value = 'BS TEST Report';
        frontPageWorkSheet.getCell('B24').alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        frontPageWorkSheet.getCell('B24').fill = {
          type: 'gradient',
          gradient: 'angle',
          degree: 270,
          stops: [
            { position: 1, color: { argb: 'ffffffff' } },
            { position: 0.75, color: { argb: 'fff6fef6' } },
            { position: 0, color: { argb: 'ffd3f8d3' } },
          ],
        };
        frontPageWorkSheet.getCell('B24').border = {
          top: { style: 'medium' },
          left: { style: 'medium' },
          bottom: { style: 'medium' },
          right: { style: 'medium' },
        };
        siteData.forEach((row, i) => {
          frontPageWorkSheet.mergeCells(`B${27 + i}:D${27 + i}`);
          frontPageWorkSheet.mergeCells(`E${27 + i}:I${27 + i}`);
          frontPageWorkSheet.getCell(`B${27 + i}`).value = row?.[0];
          frontPageWorkSheet.getCell(`B${27 + i}`).border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' },
          };
          frontPageWorkSheet.getCell(`B${27 + i}`).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
          frontPageWorkSheet.getCell(`E${27 + i}`).value = row?.[1];
          frontPageWorkSheet.getCell(`E${27 + i}`).border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' },
          };
          frontPageWorkSheet.getCell(`E${27 + i}`).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        });
        const workSheet = workBook.addWorksheet('Stationary Test');
        workSheet.properties.defaultColWidth = 30;
        workSheet.addRows(arr);
        properties?.title?.forEach(({ merge, rowNumber }) => {
          const mergeStr = `A${rowNumber}:${String.fromCharCode(
            'A'.charCodeAt(0) + workSheet.actualColumnCount,
          )}${rowNumber}`;
          workSheet.mergeCells(mergeStr);
          workSheet.getRow(rowNumber).font = {
            name: 'Arial',
            size: 11,
            bold: true,
          };
          workSheet.getRow(rowNumber).fill = {
            type: 'gradient',
            gradient: 'angle',
            degree: 270,
            stops: [
              { position: 1, color: { argb: 'ffffffff' } },
              { position: 0.5, color: { argb: 'fff6fef6' } },
              { position: 0, color: { argb: 'ffd3f8d3' } },
            ],
          };
          workSheet.getRow(rowNumber).border = {
            top: { style: 'thin', color: { argb: '00000000' } },
            bottom: { style: 'thin', color: { argb: '00000000' } },
          };
        });
        const fileBuffer = await workBook.xlsx.writeBuffer();
        saveAs(new Blob([fileBuffer], { type: 'application/octet-stream' }), `${filename}.xlsx`);
        break;
      }
      case 'pdf': {
        const pageWidth = 320;
        const pageHeight = 420;
        const doc = new JsPdf({
          orientation: 'l',
          unit: 'mm',
          format: [pageWidth, pageHeight], // Set to A3 page size 297mm x 420mm
        });
        doc.setDrawColor('#575757');
        doc.setLineWidth(0.5);
        if (!Object.keys(data).length) throw new Error('Invalid data passed to exportToPdf()');
        const PAGE_CONFIG = {
          marginTop: 25.4,
          marginLeft: 19,
        };
        const { tableData, siteData, mobilityTestData } = formatPdfData(
          data,
          siteDataResponse,
          valueMap,
        );
        let finalY = doc.lastAutoTable.finalY || 10;
        doc.setFontSize(17);
        doc.text('ATP TEST REPORT', pageHeight / 2, PAGE_CONFIG.marginTop, { align: 'center' });
        doc.setFontSize(11);
        doc.text('Site Information', PAGE_CONFIG.marginLeft, PAGE_CONFIG.marginTop + 10);
        doc.autoTable({
          startY: finalY + 30,
          margin: { left: PAGE_CONFIG.marginLeft },
          body: Object.entries(siteData),
          bodyStyles: { fontSize: 12 },
          headStyles: { fontSize: 9, cellWidth: 50 },
          columnStyles: { 0: { fontStyle: 'bold' } },
          tableWidth: 'wrap',
          theme: 'plain',
        });
        doc.text('Site Map', PAGE_CONFIG.marginLeft + 100, PAGE_CONFIG.marginTop + 10);
        // finalY += 15;
        const atpSiteMapEl = document.getElementById('atp-site-map-report');
        const canvas = await html2canvas(atpSiteMapEl, {
          useCORS: true,
          height: window.height,
          width: window.width,
          scrollX: 0,
          scrollY: 0,
        });
        const img = canvas.toDataURL('image/png');
        doc.addImage(
          img,
          'PNG',
          PAGE_CONFIG.marginLeft + 100,
          PAGE_CONFIG.marginTop + 15,
          240,
          140,
        );
        finalY = doc.lastAutoTable.finalY + 64;
        doc.line(PAGE_CONFIG.marginLeft, finalY, pageHeight - PAGE_CONFIG.marginLeft, finalY);
        finalY = finalY + 5;
        doc.setFontSize(14);
        doc.text('1. Stationary Test', PAGE_CONFIG.marginLeft, finalY + 10);
        finalY = finalY + 10;
        tableData?.forEach((tableItem, i) => {
          doc.setFont('verdana', 'normal', 'bold');
          doc.setFontSize(12);
          doc.setTextColor(111, 6, 246);
          doc.text(`1.${i + 1} ${tableItem.title}`, PAGE_CONFIG.marginLeft, finalY + 10);
          doc.autoTable({
            startY: finalY + 15,
            margin: { left: PAGE_CONFIG.marginLeft },
            head: [tableItem.headers],
            body: tableItem?.data?.map(rowData => Object.values(rowData)),
            bodyStyles: { fontSize: 12, lineColor: [265, 165, 0] },
            tableWidth: 'wrap',
            headStyles: {
              fillColor: '#ececec',
              textColor: '#1f1f1f',
              fontSize: 9,
              cellWidth: tableItem.cellWidth,
            },
            theme: 'grid',
            willDrawCell: hookData => {
              if (hookData.section === 'body') {
                const lastCellIndex = hookData.row?.raw?.length - 1 ?? 0;
                const statusCell = hookData.row?.cells?.[`${lastCellIndex}`];
                if (statusCell?.raw === 'Success' || statusCell?.raw === 'Failed') {
                  statusCell.styles.fontStyle = 'bold';
                  statusCell.styles.textColor =
                    statusCell.raw === 'Success' ? '#008000' : '#FF0000';
                }
              }
            },
          });
          finalY = doc.lastAutoTable.finalY + 5;
        });
        doc.line(PAGE_CONFIG.marginLeft, finalY, pageHeight - PAGE_CONFIG.marginLeft, finalY);
        doc.setFontSize(14);
        doc.addPage([pageWidth, pageHeight], 'l');
        doc.text('2. Mobility Test', PAGE_CONFIG.marginLeft, PAGE_CONFIG.marginTop + 10);
        // finalY = finalY + 10;
        doc.setFontSize(11);
        const mobilityTestImages = await Promise.all(
          Object.keys(mobilityTestData?.data)?.map(
            testId =>
              new Promise((resolve, reject) => {
                const map = document.getElementById(`atp-vonr-mobility-test-${testId}`);
                html2canvas(map, {
                  useCORS: true,
                  height: window.height,
                  width: window.width,
                  scrollX: 0,
                  scrollY: 0,
                })
                  .then(canvas => {
                    const img = canvas.toDataURL('image/jpeg');
                    resolve({ [testId]: img });
                  })
                  .catch(error => reject(error));
              }),
          ),
        );
        let resImages = {};
        mobilityTestImages?.forEach(test => {
          resImages = { ...resImages, ...test };
        });
        Object.keys(mobilityTestData?.data)?.forEach((testId, i) => {
          doc.autoTable({
            startY: PAGE_CONFIG.marginTop + 15,
            margin: { left: PAGE_CONFIG.marginLeft },
            head: [mobilityTestData.headers],
            body: [Object.values(mobilityTestData.data[testId])],
            bodyStyles: { fontSize: 9 },
            headStyles: {
              fillColor: '#ececec',
              textColor: '#1f1f1f',
              fontSize: 9,
              cellWidth: 28,
            },
            theme: 'grid',
          });
          finalY = doc.lastAutoTable.finalY + 5;
          doc.addImage(
            resImages[testId],
            'JPEG',
            PAGE_CONFIG.marginLeft,
            PAGE_CONFIG.marginTop + 40,
            250,
            150,
          );
          if (i < Object.keys(mobilityTestData.data).length - 1) doc.addPage([257, 364], 'l');
        });
        doc.save(filename);
        break;
      }
      default: {
        throw new Error(`Invalid type passed to downloadReport(). Expected 'csv'|'pdf'|'xlsx'`);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Formats the ATP Reports
 * @param {Array} data
 *
 */
export const formatResponse = (data = []) => {
  return {
    [TYPES.sms]: formatSMSResponse(data),
    [TYPES.vonr]: formatVoNRResponse(data),
    [TYPES.vonrMobility]: formatVoNRMobilityResponse(data),
    [TYPES.vinr]: formatViNRResponse(data),
    [TYPES.ping]: formatPingResponse(data),
    [TYPES.iperfTD]: formatIperfResponse(data, 'td'),
    [TYPES.iperfTU]: formatIperfResponse(data, 'tu'),
    [TYPES.iperfUU]: formatIperfResponse(data, 'uu'),
    [TYPES.iperfUD]: formatIperfResponse(data, 'ud'),
    [TYPES.mms]: formatMMSResponse(data),
  };
};
